<template>
    <div class="login-signup-choice">
      <h2>ようこそ</h2>
      <p>アカウントをお持ちの方はログイン、新規の方はサインインを選択してください。</p>
      <div class="button-container">
        <button @click="chooseLogin" class="choice-button">ログイン</button>
        <button @click="chooseSignup" class="choice-button">サインイン</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoginSignupChoice',
    methods: {
      chooseLogin() {
        this.$router.push({ name: 'Login' });
      },
      chooseSignup() {
        this.$router.push({ 
          name: 'Login', 
          query: { mode: 'signup' } 
        });
      }
    }
  }
</script>

<style scoped>
.login-signup-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.button-container {
  display: flex;
  gap: 20px;
}

.choice-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>