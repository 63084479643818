import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/components/LoginPage.vue';
import LoginFailedPage from '@/components/LoginFailedPage.vue';
import LoginSignupChoice from '@/components/LoginSignupChoice.vue';
import store from '@/store';

const routes = [
  { path: '/', name: 'LoginSignupChoice', component: LoginSignupChoice },
  { path: '/login-failed', name: 'LoginFailed', component: LoginFailedPage },
  {
    path: '/login',  name: 'Login',
   component: LoginPage,
   props: route => ({ 
     mode: route.query.mode || 'login'
   }) 
  },

  // Accounting Module (Lazy Loading)
  {
    path: '/accounting',
    name: 'AccountingMenu',
    component: () => import('@/components/Accounting/AccountingMenu.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/accounting/main',
    name: 'AccountingMain',
    component: () => import('@/components/Accounting/AccountingMain.vue'),
    meta: { requiresAuth: true },
  },

  // Consolidation Module (Lazy Loading)
  {
    path: '/consolidation',
    name: 'ConsolidationMenu',
    component: () => import('@/components/Consolidation/ConsolidationMenu.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/consolidation/main',
    name: 'ConsolidationMain',
    component: () => import('@/components/Consolidation/ConsolidationMain.vue'),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// ナビゲーションガード (認証チェック)
router.beforeEach((to, from, next) => {
  console.log('グローバルガード - 遷移先:', to.name);

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // グローバルストアから選択されたモジュールを取得
    const selectedModule = store.getters.selectedModule;
    console.log('選択されたモジュール:', selectedModule);

    // モジュールが選択されていない場合の処理
    if (!selectedModule) {
      console.error('モジュールが選択されていません');
      next({ name: 'LoginSignupChoice' });
      return;
    }

    // 認証状態の確認
    const isAuthenticated = store.getters[`${selectedModule}/isAuthenticated`];
    console.log('認証状態:', isAuthenticated);

    if (!isAuthenticated) {
      next({ name: 'LoginSignupChoice' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;