<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<style>
#app {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.v-application {
  min-height: 100vh !important;
}

.v-application--wrap {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
}

.v-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}
</style>

<script>

export default {
  name: 'App',
  mounted() {
      this.startInactivityTimer();
      window.addEventListener('mousemove', this.resetInactivityTimer);
      window.addEventListener('keydown', this.resetInactivityTimer);
  },
  beforeUnmount() {
      window.removeEventListener('mousemove', this.resetInactivityTimer);
      window.removeEventListener('keydown', this.resetInactivityTimer);
  },
  methods: {
    startInactivityTimer() {
        this.inactivityTimeout = setTimeout(() => {
            // ログインモジュールからlogoutUser関数を取得して実行
            const selectedModule = this.$store.state.selectedModule; // モジュール名は実際の設定に合わせて変更してください
            if (selectedModule && selectedModule.logoutUser) {
                selectedModule.logoutUser();
            }
        }, 20 * 60 * 1000);
    },
    resetInactivityTimer() {
        clearTimeout(this.inactivityTimeout);
        this.startInactivityTimer();
    }
  }
}
</script>