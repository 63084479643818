<template>
  <div class="login-container">
  <br>
   <!-- モジュール選択の表示条件を修正 -->
   <h2 v-if="!selectedModule && internalMode !== 'signup' && !isModuleAdd">モジュールを選択してください</h2>
   <h2 v-else>
       {{ isModuleAdd ? '新規モジュール追加' : 
          (internalMode === 'signup' ? '新規会社登録' : 'ログイン - ' + selectedModuleName) }}
   </h2>
   <p v-if="internalMode === 'signup'" class="signup-notice">
    登録済の会社に対するモジュールの追加はログイン後のユーザーメニューで実行して下さい
   </p>
    <!-- モジュール選択ボタンの表示条件を修正 -->
   <div v-if="!selectedModule && internalMode !== 'signup' && !isModuleAdd" class="module-buttons">
     <button @click="selectModule('accounting')" class="module-button">会計</button>
     <button @click="selectModule('consolidation')" class="module-button">連結</button>
     <button @click="selectModule('fixed_assets')" class="module-button">固定資産 [予定]</button>
     <button @click="selectModule('expense_report')" class="module-button">経費精算 [予定]</button>     
     <a @click="goToHome" class="back-link">トップページに戻る</a>
   </div>
    <!-- 会社登録/モジュール追加フォームの表示条件を修正 -->
   <div v-if="internalMode === 'signup' || isModuleAdd" class="signup-form">
     <v-form ref="form" v-model="valid" @update:model-value="updateValidation">
       <v-text-field
        label="会社管理cd"
        v-model="companyInfo.code"
        :rules="isModuleAdd ? [] : [rules.required, rules.numeric]"
        :disabled="isModuleAdd"
        ></v-text-field>
        <v-text-field
        label="会社名"
        v-model="companyInfo.name"
        :rules="[rules.required]"
        :disabled="isModuleAdd"
        ></v-text-field>
        <v-text-field
        label="事業年度_開始年月(yyyymm)"
        v-model="companyInfo.fiscalYearStart"
        :rules="[rules.required, rules.length6, rules.numeric, rules.fiscalYear]"
        :disabled="isModuleAdd"
        ></v-text-field>
        <v-text-field
        label="事業年度_終了年月(yyyymm)"
        v-model="companyInfo.fiscalYearEnd"
        :rules="[rules.required, rules.length6, rules.numeric, rules.fiscalYear]"
        :disabled="isModuleAdd"
        ></v-text-field>
        <v-text-field
        label="管理者ユーザーcd"
        v-model="companyInfo.adminUserCd"
        :rules="isModuleAdd ? [] : [rules.required, rules.numeric]"
        :disabled="isModuleAdd"
        ></v-text-field>

        <!-- モジュール選択の追加 -->
        <v-select
          v-model="companyInfo.selectedModules"
          :items="availableModules"
          item-title="name"
          item-value="key"
          label="利用モジュール"
          :rules="[rules.required]"
        ></v-select>
     </v-form>
     <!-- Google/Microsoft登録ボタン -->
     <div class="login-buttons">
       <div v-if="valid" class="auth-message">
         会社情報を入力後、以下のいずれかで認証を行ってください
       </div>
       <div class="g-signin2" 
         data-width="280"
         data-height="50"
         data-longtitle="true"
         data-theme="light">
       </div>
       <button @click="loginWithMicrosoft" class="ms-login-button">
         <img src="@/assets/microsoft-logo.png" alt="Microsoft logo" class="ms-logo" />
         {{ internalMode === 'signup' ? 'Microsoftで登録' : 'Microsoftでログイン' }}
       </button>
     </div>
   </div>

   <!-- ログインボタンの表示条件を追加 -->
   <div v-if="selectedModule && internalMode !== 'signup'" class="login-buttons">
     <div class="g-signin2" 
       data-width="280"
       data-height="50"
       data-longtitle="true"
       data-theme="light">
     </div>
     <button @click="loginWithMicrosoft" class="ms-login-button">
       <img src="@/assets/microsoft-logo.png" alt="Microsoft logo" class="ms-logo" />
       {{ internalMode === 'signup' ? 'Microsoftで登録' : 'Microsoftでログイン' }}
     </button>
   </div>

    <!-- 戻るリンク -->
   <a v-if="selectedModule || internalMode === 'signup'" @click="resetModule" class="back-link">
     {{ internalMode === 'signup' ? 'トップページに戻る' : 'モジュール選択に戻る' }}
   </a>

   <!-- OTP入力ダイアログ -->
   <v-dialog v-model="showOtpDialog" persistent max-width="400">
     <v-card>
       <v-card-title>ワンタイムパスワードの確認</v-card-title>
       <v-card-text>
         <p>メールに送信されたワンタイムパスワードを入力してください。</p>
         <v-text-field
           v-model="otp"
           label="ワンタイムパスワード"
           type="text"
           required
         ></v-text-field>
       </v-card-text>
       <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn color="primary" @click="verifyOtp">確認</v-btn>
         <v-btn @click="showOtpDialog = false">キャンセル</v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
 </div>
</template>

<script>
import msalInstance from '@/msalInstance';

export default {
  props: {
    mode: {
      type: String,
      default: 'login'
    }
  },
  data() {
    return {
      // 内部モード状態を追加
      internalMode: 'login',
      selectedModule: null,
      googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      googleLoginUri: process.env.VUE_APP_GOOGLE_LOGIN_URI,
      azureClientId: process.env.VUE_APP_AZURE_CLIENT_ID, // 追加
      azureRedirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI, // 追加
      companyInfo: {
       code: "",
       name: "",
       fiscalYearStart: "",
       fiscalYearEnd: "",
       adminUserCd: "",
       email: "",
       selectedModules: "", // 配列から文字列に変更
      },
      valid: false,
      rules: {
        required: v => !!v || "必須項目です",
        numeric: v => /^\d+$/.test(v) || "数値で入力してください",
        length4: v => (v && v.length === 4) || "4桁で入力してください",
        length6: v => (v && v.length === 6) || "6桁で入力してください",
        fiscalYear: v => {
          if (!v) return true;
          const strValue = String(v); // 文字列に変換
          if (strValue.length !== 6) return "6桁で入力してください";
          
          const year = parseInt(strValue.slice(0, 4));
          const month = parseInt(strValue.slice(4, 6));
          
          if (isNaN(year) || isNaN(month)) return "正しい年月を入力してください";
          if (month < 1 || month > 12) return "月は1から12の間で入力してください";
          
          return true;
        },  
      },
      // 利用可能なモジュールのリスト
      availableModules: [
        { key: 'accounting', name: '会計' },
        { key: 'consolidation', name: '連結' },
      ],
      showOtpDialog: false,
      otp: '',
      tempCompanyInfo: null,
      isModuleAdd: false,
    };
  },
  computed: {
    selectedModuleName() {
      const modules = {
        accounting: '会計',
        consolidation: '連結',
        expense_report: '経費精算',
        fixed_assets: '固定資産',
      };
      return modules[this.selectedModule] || '';
    },
  },
  methods: {
    chooseSignup() {
      // 'Signup' ルートへの遷移を 'Login' ルートへのモードパラメータ付き遷移に変更
      this.$router.push({ 
        name: 'Login', 
        query: { mode: 'signup' } 
      });
    },
    switchMode() {
      // フォームをリセット
      if (this.internalMode === 'signup') {
        this.companyInfo = {
          code: "",
          name: "",
          fiscalYearStart: "",
          fiscalYearEnd: "",
          adminUserCd: "",
          email: "",
          selectedModules: "", // 配列から文字列に変更
        };
        if (this.$refs.form) {
          this.$refs.form.reset();
        }
      }
    },
    selectModule(moduleKey) {
      // グローバルストアのミューテーションを呼び出し
      this.$store.commit('setSelectedModule', moduleKey);
      
      if (moduleKey === 'fixed_assets' || moduleKey === 'expense_report') {
        alert('このモジュールは現在未実装です。');
        return;
      }
      this.selectedModule = moduleKey; // 選択されたモジュールを記録
      this.initializeGoogleSignIn(); // Google ログインボタンを初期化
    },
    resetModule() {
      if (this.internalMode === 'signup') {
        this.$router.push('/'); // トップページに戻る
      } else {
        this.selectedModule = null; // モジュール選択をリセット
      }
    },
    initializeGoogleSignIn() {
      let retryCount = 0;
      const maxRetries = 10;
      const retryInterval = 500;

      const checkGoogleApiLoaded = () => {
        if (typeof google !== 'undefined' && document.querySelector('.g-signin2')) {
          google.accounts.id.initialize({
            client_id: this.googleClientId,
            callback: this.handleCredentialResponse,
          });
          google.accounts.id.renderButton(
            document.querySelector('.g-signin2'),
            { 
              theme: 'outline', 
              size: 'large', 
              width: 280, 
              text: this.internalMode === 'signup' ? 'signup_with' : 'signin_with',
              logo_alignment: 'left',
              height: 50 
            }
          );
          clearInterval(intervalId);
        } else {
          retryCount++;
          if (retryCount >= maxRetries) {
            clearInterval(intervalId);
            console.error('Google API の読み込みに失敗しました');
          }
        }
      };

      const intervalId = setInterval(checkGoogleApiLoaded, retryInterval);
    },
    async handleCredentialResponse(response) {
      if (this.internalMode === 'signup') {
        await this.handleSignup('google', response.credential);
      } else {
        // 選択されたモジュールに基づいて動的にAPIパスを生成
        const apiPath = `/api/global/authenticate.php`;
        console.log('this.selectedModule:',this.selectedModule);

        fetch(apiPath, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: response.credential, provider: 'google', module: this.selectedModule }),
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return response.json().then(err => {
            throw new Error(err.error || 'Network response was not ok.');
          });
        })
        .then((data) => {
          if (!data.success) {
            throw new Error(data.error || 'ログインに失敗しました');
          }
          this.handleSuccessfulLogin(data);
        })
        .catch((error) => {
          console.error('ログインエラー:', error.message);
          this.$router.push({ 
            name: 'LoginFailed',
            query: { message: error.message }
          });
        });
      }
    },
    async loginWithMicrosoft() {
      try {
        const loginRequest = { scopes: ['User.Read'] };
        const response = await msalInstance.loginPopup(loginRequest);
        const userInfoResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
          headers: { Authorization: `Bearer ${response.accessToken}` },
        });
        if (!userInfoResponse.ok) {
          throw new Error('Failed to fetch Microsoft user information');
        }
        const userData = await userInfoResponse.json();
        
        // ユーザーデータを加工して姓名の順序を調整
        const formattedUserData = {
          ...userData,
          displayName: `${userData.surname} ${userData.givenName}`.trim()
        };
        
        if (this.internalMode === 'signup') {
          await this.handleSignup('microsoft', formattedUserData);
        } else {
          const apiPath = `/api/global/authenticate.php`;
          fetch(apiPath, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userData: formattedUserData, provider: 'microsoft', module: this.selectedModule }),
          })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            return response.json().then(err => {
              throw new Error(err.error || 'Network response was not ok.');
            });
          })
          .then((data) => {
            if (!data.success) {
              throw new Error(data.error || 'ログインに失敗しました');
            }
            this.handleSuccessfulLogin(data);
          })
          .catch((error) => {
            console.error('ログインエラー:', error.message);
            this.$router.push({ 
              name: 'LoginFailed',
              query: { message: error.message }
            });
          });
        }
      } catch (error) {
        console.error('Microsoftログインに問題がありました:', error);
        this.$router.push({ 
          name: 'LoginFailed',
          query: { message: error.message }
        });
      }
    },
    async handleSignup(provider, authData) {
      // フォームの再検証を強制的に実行
      await this.$refs.form.validate();
      
      if (!this.valid) {
        alert('入力内容を確認してください');
        return;
      }

      // 事業年度の妥当性チェック
      const startDate = parseInt(this.companyInfo.fiscalYearStart);
      const endDate = parseInt(this.companyInfo.fiscalYearEnd);
      if (startDate >= endDate) {
        alert('事業年度の開始年月は終了年月より前である必要があります');
        return;
      }

      try {
        const requestData = {
          companyCode: this.companyInfo.code,
          companyName: this.companyInfo.name,
          fiscalYearStart: this.companyInfo.fiscalYearStart,
          fiscalYearEnd: this.companyInfo.fiscalYearEnd,
          adminUserCd: this.companyInfo.adminUserCd,
          selectedModules: this.companyInfo.selectedModules,
          authProvider: provider,
          authData: authData
        };

        const response = await fetch("/api/global/register_company.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData)
        });

        const data = await response.json();
        if (data.success) {
          this.showOtpDialog = true;
          this.tempCompanyInfo = { ...this.companyInfo };
        } else {
          // サーバーからのエラーメッセージを表示
          alert(data.message || "登録に失敗しました");
        }
      } catch (error) {
        console.error('登録エラー:', error);
        alert("エラーが発生しました。時間をおいて再度お試しください。");
      }
    },
    async verifyOtp() {
      if (!this.otp.trim()) {
        alert('ワンタイムパスワードを入力してください。');
        return;
      }

      try {
        const response = await fetch('/api/global/confirm_registration.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ otp: this.otp })
        });

        if (!response.ok) {
          throw new Error('ネットワークレスポンスが正しくありません。');
        }

        const data = await response.json();
        if (data.success) {
          alert(data.message);
          this.showOtpDialog = false;
          this.$router.push('/');
        } else {
          alert(data.message);
        }
      } catch (error) {
        console.error('Error verifying OTP:', error);
        alert('ワンタイムパスワード検証中にエラーが発生しました。');
      }
    },
    handleSuccessfulLogin(data) {      
      let targetCompanyFormatted;
      let wireguardFormatted = [];

      if (Array.isArray(data.targetCompany)) {
        targetCompanyFormatted = data.targetCompany.length === 1 ? data.targetCompany[0] : '未選択';
      } else {
        targetCompanyFormatted = data.targetCompany || '未選択';
      }

      if (Array.isArray(data.wireguard) && data.wireguard.length > 0) {
        wireguardFormatted = data.wireguard.map(wg => ({
          id: wg.id,
          name: wg.name,
          code: wg.code,
          wireguard: wg.wireguard,
        }));
      }

      // モジュールごとのストア設定
      const storeConfig = {
        accounting: {
          mutation: 'accounting/setLoginDetails',
          loginMutation: 'accounting/login'
        },
        consolidation: {
          mutation: 'consolidation/setLoginDetails',
          loginMutation: 'consolidation/login'
        }
      };

      try {
        const config = storeConfig[this.selectedModule];
        if (!config) {
          throw new Error(`未定義のモジュール: ${this.selectedModule}`);
        }
        console.log('config:',config);
        // 選択されたモジュールに応じてストアを更新
        this.$store.commit(config.mutation, {
          username: data.username,
          email: data.email,
          companyCode: data.companyCode,
          targetCompany: targetCompanyFormatted,
          targetPeriod: '',
          wireguard: wireguardFormatted,
          plan_status: data.plan_status,
          end_date: data.end_date,
        });
        this.$store.commit(config.loginMutation);

        // 遷移処理
        const routeConfig = {
          accounting: {
            name: 'AccountingMenu',
            query: { module: 'accounting' }
          },
          consolidation: {
            name: 'ConsolidationMenu',
            query: { module: 'consolidation' }
          }
        };

        const route = routeConfig[this.selectedModule];
        this.$router.push(route).catch(err => {
          console.error('ルーティングエラー:', err);
        });

      } catch (error) {
        console.error('ログイン処理エラー:', error);
        this.$router.push({ name: 'LoginFailed' });
      }
    },
    goToHome() {
      this.$router.push('/');
    },
    updateValidation(value) {
      this.valid = value;
      console.log('Form validation status:', value);
    },
  },
  created() {
    // 初期化時にpropからinternalModeを設定
    this.internalMode = this.mode;
    
    // URLクエリパラメータをチェック
    this.isModuleAdd = this.$route.query.mode === 'module-add';
    
    if (this.isModuleAdd) {
      // ストアから保存された会社情報を取得
      const companyInfo = this.$store.state.global.companyInfoForModuleAdd;
      console.log('companyInfo:', companyInfo);
      this.companyInfo = {
        code: companyInfo.code,
        name: companyInfo.name,
        fiscalYearStart: companyInfo.fiscalYearStart || "",
        fiscalYearEnd: companyInfo.fiscalYearEnd || "",
        adminUserCd: companyInfo.adminUserCd || 'system',
        selectedModules: "",
      };
      // internalMode を 'signup' に設定
      this.internalMode = 'signup';
    }
  },
  mounted() {
    // モードに応じてGoogle Sign-Inを初期化
    if (this.internalMode === 'signup' || this.selectedModule) {
      this.initializeGoogleSignIn();
    }
  },
  watch: {
    // propのmodeの変更を監視してinternalModeを更新
    mode: {
      handler(newMode) {
        this.internalMode = newMode;
      },
      immediate: true
    }
  }
};
</script>

<style>
/* ルート要素とbodyに高さを設定 */
:root, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-height: 100vh; /* コンテナの最小高さを画面の高さに設定 */
  margin: 0; /* マージンを削除 */
  padding: 20px 0; /* 上下のパディングのみ設定 */
  box-sizing: border-box; /* パディングを高さに含める */
  background-color: #f8f9fa; /* 背景色を設定（必要に応じて） */
}

/* 最後の要素の下マージンを削除 */
.login-container > *:last-child {
  margin-bottom: 0;
}

.module-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.module-button {
  width: 280px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ffffff;
  color: #333;
}

.module-button:hover {
  background-color: #f7f7f7;
}

.login-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.g-signin2 {
  width: 280px;
  margin-bottom: 10px;
}

.ms-login-button {
  width: 280px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.ms-logo {
  width: 20px;
  height: 20px;
}

.back-link {
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.back-link:hover {
  text-decoration: underline;
}

.module-buttons .back-link {
  margin-top: 20px;
  text-align: center;
}
.signup-form {
 width: 100%;
 max-width: 500px;
 margin: 0 auto;
 padding: 20px;
}
mode-switch {
 margin: 10px 0;
 text-align: center;
}
.mode-link {
 color: #007bff;
 cursor: pointer;
 text-decoration: underline;
}
auth-message {
 margin: 15px 0;
 color: #666;
 text-align: center;
 font-size: 0.9em;
}
.login-buttons {
 margin-top: 20px;
}
.signup-form {
 width: 100%;
 max-width: 500px;
 margin: 0 auto;
 padding: 20px;
 background-color: #f8f9fa;
 border-radius: 8px;
 box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.signup-notice {
  font-size: 0.8em;
  color: #666;
  margin-top: -10px;
  margin-bottom: 20px;
  text-align: center;
}
</style>
