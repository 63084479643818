export default {
    namespaced: true,
    
    state: {
      companyInfoForModuleAdd: null
    },
    
    getters: {
      getCompanyInfoForModuleAdd: state => state.companyInfoForModuleAdd
    },
    
    mutations: {
      setCompanyInfoForModuleAdd(state, companyInfo) {
        state.companyInfoForModuleAdd = companyInfo;
      },
      
      clearCompanyInfoForModuleAdd(state) {
        state.companyInfoForModuleAdd = null;
      }
    }
  };