export default {
    namespaced: true,
    state: {
      userPermission: {
        permission: null,
        user_cd: null
      },
      periods: [],
      previousFyYm: null,
      plan_status: null,  // 追加
      end_date: null,    // 追加
      masterMappingFetched: false,
      companyMapping: [],
      accMapping: [],
      subMapping:  [],
      segMapping: [],
      ptnMapping: [],
      typ1Mapping: [],
      typ2Mapping: [],
      userMapping: [],
      fyMapping: [],
      ymMapping: [],
      tableQueries: null, // この行を追加
      isLoggedIn: false,
      email: '',
      username: '',
      targetPeriod: '',
      targetCompany: '',
      currentActiveTab: null,
      queriesForStyle: [],
      allTabs: []  // すべてのタブの情報を保持する配列
    },
    mutations: {
      setPeriods(state, periods) {
        state.periods = periods;
      },
      setPreviousFyYm(state, value) {
        state.previousFyYm = value;
      },
      setMasterMappingFetched(state, value) {
        state.masterMappingFetched = value;
      },
      setMappings(state, mappings) {
        // mappingsが存在し、オブジェクトであることを確認
        if (mappings && typeof mappings === 'object') {
          Object.keys(mappings).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(state, key) && Array.isArray(mappings[key])) {
              state[key] = mappings[key];
            }
          });
        }
      },
      setUserPermission(state, payload) {
          state.userPermission = {
              permission: payload.permission,
              user_cd: payload.user_cd
          };
      },
      setCompanyMapping(state, value) {
        state.companyMapping = value;
      },
      setAccMapping(state, value) {
        state.accMapping = value;
      },
      setSubMapping(state, value) {
        state.subMapping = value;
      },
      setSegMapping(state, value) {
        state.segMapping = value;
      },
      setPtnMapping(state, value) {
        state.ptnMapping = value;
      },
      setTyp1Mapping(state, value) {
        state.typ1Mapping = value;
      },
      setTyp2Mapping(state, value) {
        state.typ2Mapping = value;
      },
      setYmMapping(state, value) {
        state.ymMapping = value;
      },
      setFyMapping(state, value) {
        state.fyMapping = value;
      },
      setUserMapping(state, value) {
        state.userMapping = value;
      },
      SET_QUERIES_FOR_STYLE(state, queriesForStyle) {
        state.queriesForStyle = queriesForStyle;
      },
      RESET_TABLE_QUERIES(state) {
        state.tableQueries = {}; // 初期化またはクリアする値によって異なる
      },
      RESET_QUERIES(state) {
        state.queries = {}; // 初期化またはクリアする値によって異なる
      },
      SET_QUERIES(state, queries) {
        state.queries = queries;
      },
      SET_TABLEQUERIES(state, tableQueries) {
          state.tableQueries = tableQueries;
        },
      login(state) {
        state.isLoggedIn = true;
      },
      logout(state) {
        state.isLoggedIn = false;
        state.email = '';
        state.username = '';
        state.targetPeriod = '';
        state.targetCompany = '';
        state.currentActiveTab = null;
        state.allTabs = [];
      },
      setLoginDetails(state, payload) {
        state.username = payload.username;
        state.email = payload.email; // emailをstateに追加する必要があります
        state.companyCode = payload.companyCode;
        state.targetPeriod = payload.targetPeriod;
        state.targetCompany = payload.targetCompany;
        state.wireguard = payload.wireguard;
        // デバッグログを追加
        console.log('Login payload:', {
          plan_status: payload.plan_status,
          end_date: payload.end_date
        });
        state.plan_status = payload.plan_status;
        state.end_date = payload.end_date;
      },
      setTargetPeriod(state, period) {
        state.targetPeriod = period;
      },
      setTargetCompany(state, company) { // 新しいmutationを追加
        state.targetCompany = company;
      },
      resetTargetPeriod(state) {
        state.targetPeriod = null; // または適切な初期値にリセット
      },
      SET_ACTIVE_TAB(state, tab) {
          state.currentActiveTab = tab;
          // console.log("Active tab set to:", state.currentActiveTab);
      },
      SET_ALL_TABS(state, tabs) {
          state.allTabs = tabs;  // すべてのタブの情報をセット
          // console.log("state.allTabs:", tabs);
      },
      ADD_TAB(state, newTab) {
          if (!state.allTabs.some(tab => tab.id === newTab.id)) {
              state.allTabs.push(newTab);
          }
      },
      REMOVE_TAB(state, tabIndex) {
          state.allTabs.splice(tabIndex, 1);
      },
      UPDATE_TAB(state, { tabIndex, updatedTab }) {
          state.allTabs[tabIndex] = updatedTab;
      }   
    },
    actions: {
      async login({ commit }, { credentials, mode }) {
        // modeに応じてログインまたはサインアップの処理を行う
        const endpoint = mode === 'signup' ? '/auth/signup' : '/auth/login';
        try {
          const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
          });
          if (!response.ok) {
            throw new Error('ログインに失敗しました');
          }
          const data = await response.json();
          commit('setUser', data.user);
          // ... その他の処理 ...
        } catch (error) {
          console.error('ログインエラー:', error);
          // エラー処理
        }
      },
      logoutUser({ commit }) {
          commit('logout');
          // ログアウト後にログインページにリダイレクトする処理
          window.location.href = '/login'; // ログインページにリダイレクト
      },
      // ここで非同期のログイン処理やAPI呼び出しを行うことができます
      addTab({ commit, state }, tabId) {
          if (!state.allTabs.some(tab => tab.id === tabId)) {
            const tabData = { id: tabId, name: tabId, action: tabId };
            const updatedTabs = [...state.allTabs, tabData];
            commit('ADD_TAB', tabData);
            commit('SET_ACTIVE_TAB', tabData); // 追加したタブをアクティブにする
            commit('SET_ALL_TABS', updatedTabs);
            // console.log('Updated allTabs:', updatedTabs);
          }
      },
      removeTab({ commit, state }, tabName) {
          const tabIndex = state.allTabs.findIndex(tab => tab.name === tabName);
          if (tabIndex !== -1) {
              commit('REMOVE_TAB', tabIndex);
          }
      },
      async fetchMasterMapping({ commit, state }, getMasterMappingFunction) {
        if (!state.masterMappingFetched) {
          try {
            const mappings = await getMasterMappingFunction();
            commit('setMappings', mappings);
            commit('setMasterMappingFetched', true);
          } catch (error) {
            console.error('Error fetching master mapping:', error);
            throw error;
          }
        }
      },
    },
    getters: {
      getUserPermission: state => state.userPermission.permission,
      getUserCd: state => state.userPermission.user_cd,
      getPeriods: state => state.periods,
      getPreviousFyYm: state => state.previousFyYm,
      isAuthenticated(state) {
        return state.isLoggedIn;
      },
      loginDetails(state) {
        return {
          email: state.email,
          username: state.username,
          targetPeriod: state.targetPeriod,
          targetCompany: state.targetCompany,
          wireguard: state.wireguard,
          plan_status: state.plan_status,
          end_date: state.end_date
        };
      },
      shouldShowAds: (state) => {
         // plan_statusがnullの場合はfreeとして扱う
         const currentPlanStatus = state.plan_status || 'free';
         const isPlanExpired = state.end_date ? new Date(state.end_date) < new Date() : true;
         const disableAds = true; // 開発中など一時的に広告を無効化するフラグ
         const shouldShow = !disableAds && (currentPlanStatus === 'free' || isPlanExpired);

         console.log('Ad display calculation:', {
             currentPlanStatus,
             isPlanExpired,
             shouldShow
         });
  
         return shouldShow;
      }
    }
  };